import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ephys/Development/reworkjs/core/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "server-side-rendering"
    }}>{`Server Side Rendering`}</h1>
    <p>{`TODO`}</p>
    <p>{`-> Isomorphic code! Link to react docs
-> How to fetch data on server (hooks)
-> Multi-render pass system
-> how to use HTTP/2 server push
-> how to send headers (automatically sent & manually sent through useRes)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      